(function(w, undefined) {

	'use strict';

	var d = w.document,
		$ = w.jQuery,
		b = w.jQuery(document.body),
		domReady = function() {

			// mobile touch delay fix
			FastClick.attach(document.body);

		},
		winLoaded = function() {

			// init all objects on window loaded

		};


	$(d).on('ready', domReady);
	$(w).on('load', winLoaded);

}(window));